<template>
  <div class="footer">
      <div class="footer_container">
        <div class="grid footer_group hidden-xs-only">
            <div class="grid_md_3 grid_sm_4 grid_xs_12  group_wrapper">
                <div class="wrapper_title flex">
                    <div class="title_icon"><img src="../assets/icon/suoyouyouxi.png" alt=""></div>
                    <div class="title_text">{{ $t('key28') }}</div>
                </div>
                <div class="wrapper_item flex" v-for="(item,index) in navigationList" :key="index">
                    <div class="wrapper_cont flex_start_center" @click="toPage('/details',item.mainGoodId,item.site)">
                        <div class="wrapper_tab" >{{ item.transName }}</div>
                        <div class="tab_icon " v-if="index==0"><img src="../assets/icon/remenyouxi.png" alt=""></div>
                    </div>
                </div>
               

            </div>
            <div class="grid_md_3 grid_sm_4 grid_xs_12 group_wrapper">
                <div class="wrapper_title flex">
                    <div class="title_icon"><img src="../assets/icon/zhonghefuwu.png" alt=""></div>
                    <div class="title_text">{{ $t('key92') }}</div>
                </div>
                <div class="wrapper_item flex" v-for="(item,index) in serveList" :key="index">
                    <div class="wrapper_link_tab" @click="toPage(item.remark,item.id)" v-if="item.showStatus==1">{{ item.serviceName }}</div> 
                </div>
                <!-- <div class="wrapper_item flex" @click="toPage('/terms')">
                    <div class="wrapper_link_tab">{{ $t('key35') }}</div> 
                </div>
                <div class="wrapper_item flex" @click="toPage('/privacyPolicy')">
                    <div class="wrapper_link_tab">{{ $t('key36') }}</div> 
                </div>
                <div class="wrapper_item flex" @click="toPage('/cookiePolicy')">
                    <div class="wrapper_link_tab">{{ $t('key37') }}</div> 
                </div>
                <div class="wrapper_item flex" @click="toPage('/faqs')">
                    <div class="wrapper_link_tab">{{ $t('key38') }}</div> 
                </div> -->
            </div>
            <!-- <div class="grid_md_3 grid_sm_4 grid_xs_12 group_wrapper">
                <div class="wrapper_title flex">
                    <div class="title_icon"><img src="../assets/icon/youqinglianjie.png" alt=""></div>
                    <div class="title_text">Friendly Links</div>
                </div>
                <div class="wrapper_item flex">
                    <div class="wrapper_link_tab">MuMuPlayer Pro</div> 
                </div>
                <div class="wrapper_item flex">
                    <div class="wrapper_link_tab">SafeShell VPN</div> 
                </div>
                <div class="wrapper_item flex">
                    <div class="wrapper_link_tab">GearUP Booster</div> 
                </div>
                
            </div> -->
            <div class="grid_md_3 grid_sm_12 grid_xs_12 group_wrapper">
                <div class="wrapper_title flex">
                    <div class="title_icon" ><img src="../assets/icon/guanzhuwomen.png" alt=""></div>
                    <div class="title_text">{{ $t('key39') }}</div>
                </div>
                <div class="wrapper_item flex">
                   <div class="item_icon">
                    <a href="https://discord.gg/J3bEJrUwbd" target="_blank">
                        <img src="../assets/icon/guanzhu_1.png" alt="">
                    </a>
                     
                   </div>
                   <div class="item_icon">
                    <a href="https://www.tiktok.com/@playsavefirst" target="_blank">
                        <img src="../assets/icon/guanzhu_2.png" alt="">
                    </a>
                     
                   </div>
                   <div class="item_icon">
                    <a href="https://x.com/playsavefi86253" target="_blank">
                        <img src="../assets/icon/guanzhu_3.png" alt="">
                    </a>
                     
                   </div>
                   <div class="item_icon">
                    <a href="https://www.youtube.com/@Playsavefirst" target="_blank">
                        <img src="../assets/icon/guanzhu_4.png" alt="">
                    </a>
                     
                   </div>
                </div>
               
                
            </div>
        </div>
        <div class="mobile_footer_group hidden-sm-and-up">
            <el-collapse accordion>
                <el-collapse-item>
                    <template slot="title">
                    <div class="wrapper_title flex">
                        <div class="title_icon flex_center"  style="width:20px;margin-right:8px;" key=""><img src="../assets/icon/suoyouyouxi.png" alt=""></div>
                        <div class="title_text">{{ $t('key28') }}</div>
                    </div>
                    </template>
                    <div class="flex_warp">
                        <div class="wrapper_item " v-for="(item,index) in navigationList" :key="index">
                            <div class="wrapper_cont flex_start_center" @click="toPage('/details',item.mainGoodId,item.site)">
                                <div class="wrapper_tab">{{ item.transName }}</div>
                                <div class="tab_icon " v-if="index==0"><img src="../assets/icon/remenyouxi.png" alt=""></div>
                            </div>
                        </div>
                        
                    </div>
                </el-collapse-item>
                <el-collapse-item>
                    <template slot="title">
                    <div class="wrapper_title flex">
                        <div class="title_icon flex_center" style="width:20px;margin-right:8px;"><img src="../assets/icon/zhonghefuwu.png" alt=""></div>
                        <div class="title_text">{{ $t('key92') }}</div>
                    </div>
                    
                    </template>
                    <div class="coll_container">
                        <div class="wrapper_item flex" v-for="(item,index) in serveList" :key="index">
                            <div class="wrapper_link_tab" @click="toPage(item.remark,item.id)" v-if="item.showStatus==1">{{ item.serviceName }}</div> 
                        </div>
                        <!-- <div class="wrapper_item flex" @click="toPage('/terms')">
                            <div class="wrapper_link_tab">{{ $t('key35') }}</div> 
                        </div>
                        <div class="wrapper_item flex" @click="toPage('/privacyPolicy')">
                            <div class="wrapper_link_tab">{{ $t('key36') }}</div> 
                        </div>
                        <div class="wrapper_item flex" @click="toPage('/cookiePolicy')">
                            <div class="wrapper_link_tab">{{ $t('key37') }}</div> 
                        </div>
                        <div class="wrapper_item flex" @click="toPage('/faqs')">
                            <div class="wrapper_link_tab">{{ $t('key38') }}</div> 
                        </div> -->
                    </div>
                </el-collapse-item>
            </el-collapse>
            <div class="group_wrapper" style="padding:16px 0;">
                <div class="wrapper_title flex">
                    <div class="title_icon" ><img src="../assets/icon/guanzhuwomen.png" alt=""></div>
                    <div class="title_text">{{ $t('key39') }}</div>
                </div>
                <div class="wrapper_item flex">
                   <div class="item_icon">
                    <a href="https://discord.gg/J3bEJrUwbd" target="_blank">
                        <img src="../assets/icon/guanzhu_1.png" alt="">
                    </a>
                   </div>
                   <div class="item_icon">
                    <a href="https://www.tiktok.com/@playsavefirst" target="_blank">
                        <img src="../assets/icon/guanzhu_2.png" alt="">
                    </a>
                   </div>
                   <div class="item_icon">
                    <a href="https://x.com/playsavefi86253" target="_blank">
                        <img src="../assets/icon/guanzhu_3.png" alt="">
                    </a>
                   </div>
                   <div class="item_icon">
                    <a href="https://www.youtube.com/@Playsavefirst" target="_blank">
                        <img src="../assets/icon/guanzhu_4.png" alt="">
                    </a>  
                   </div>
                </div>  
            </div>
        </div>
      </div>
      <div class="login_hidden empty"></div>
  </div>
</template>

<script>
import {processString} from '../utils/tool'
export default {
    data(){
        return{
            defaultList:[
                {categoryId:'1905',categoryId_act:'2838093181833339',categoryName_CH:'原神',categoryName:'Genshin Impact',},
                {categoryId:'1903',categoryId_act:'2837902080954465',categoryName_CH:'PUBG绝地求生',categoryName:'PUBG MOBILE'},
                {categoryId:'1874',categoryId_act:'299624698956623916',categoryName_CH:'寒霜启示录',categoryName:'Whiteout Survival'},
                {categoryId:'1786',categoryId_act:'16029026914041903',categoryName_CH:'荒野乱斗',categoryName:'Brawl Stars',},
                {categoryId:'1598',categoryId_act:'18202021793353829',categoryName_CH:'皇室战争',categoryName:'Clash Royale',},
                // {categoryId:'3160750788210731',categoryId_act:'',categoryName_CH:'无尽对决',categoryName:'Mobile Legends: Bang Bang',},
                // {categoryId:'62509959123648635',categoryId_act:'67326264711684131',categoryName_CH:'胜利女神',categoryName:'Goddess of Victory: Nikke'},
                // {categoryId:'258629997234995205',categoryId_act:'4766562704994428',categoryName_CH:'部落冲突',categoryName:'Clash of Clans'},
                // {categoryId:'102468010732458043',categoryId_act:'17844468639060068',categoryName_CH:'使命召唤',categoryName:'Call of Duty: Mobile'},
                // {categoryId:'326082764176007246',categoryId_act:'18331920017379355',categoryName_CH:'我要活下去',categoryName:'Free Fire'},

                // {categoryId:'307600968435396730',categoryId_act:'',categoryName_CH:'卡通爆破',categoryName:'Toon Blast'},
                // {categoryId:'17965713565143082',categoryId_act:'',categoryName_CH:'罗布乐思',categoryName:'Roblox',},
                // {categoryId:'272799858591785078',categoryId_act:'',categoryName_CH:'NBA 2K24',categoryName:'NBA 2K24',},
                // {categoryId:'307599887148019722',categoryId_act:'',categoryName_CH:'梦想城镇',categoryName:'Township',},
                // {categoryId:'246746372672237654',categoryId_act:'',categoryName_CH:'大富翁GO',categoryName:'MONOPOLY GO',},
                
                // {categoryId:'147464881456955402',categoryId_act:'',categoryName_CH:'暗影突围',categoryName:'Arena Breakout',},
                // {categoryId:'246370387618328699',categoryId_act:'',categoryName_CH:'王者荣耀',categoryName:'Honor of Kings',},
                // {categoryId:'21319528855531577',categoryId_act:'',categoryName_CH:'白夜极光',categoryName:'Alchemy Stars',},
                // {categoryId:'212251611968204837',categoryId_act:'',categoryName_CH:'合金彈頭',categoryName:'Metal Slug Awakening',},
                // {categoryId:'18571288315330580',categoryId_act:'',categoryName_CH:'云顶之弈',categoryName:'TFT Teamfight Tactics',},
                // {categoryId:'18927037910843479',categoryId_act:'',categoryName_CH:'DNF地下城与勇士',categoryName:'Dungeon & Fighter Mobile'},
            ],
            pageIndex:1,
            pageSize:10,
            // serveList:[],
            navigationList:[]

        }
    },
    created(){
    //    this.getSerConfig()
       this.getNavigationList()
    //    this.configServiceTranslation()
    },
    methods:{
        toPage(path,id,allName){
            let lang = localStorage.getItem('lang')
            if(id){      
                let tranName = allName
                let name = ''
                let gameName = ''
                if(!/^\s*$/.test(allName)){
                    if(typeof(allName) === 'string'){
                        tranName = JSON.parse(allName)
                    }            
                    tranName.map(c=>{
                    if(c.language =='1' ){
                        name = c.name
                    }  
                    })
                }
                
                gameName = processString(name)
                this.$router.push(path+'/'+lang+'/'+id+'/'+gameName)
            
                return
            }
            this.$router.push(path+'/'+lang)
        },
        
        getNavigationList(){
            let localeUrl = window.location.host
            let domain = 'https://'+localeUrl
            this.$axiosApi.navigationList({domain}).then(res=>{
                if(res.code == 200){
                  let data = res.data
                
                  this.navigationList = data.map(v=>{
                    if(!/^\s*$/.test(v.site)){
                       v.site = JSON.parse(v.site)         
                       let lang = this.$store.state.lang.dictValue
                       v.site.map(s=>{
                        if(s.language == lang){
                            v.transName = s.name
                        }
                       })
                    }
                   
                    return v
                  })
                 
                }
            })
        },
        getSerConfig(){
            let language = this.$store.state.lang.dictValue
            let _this = this
            if(!language){
                setTimeout(()=>{
                    _this.getSerConfig()
                },1000)
                return
            }
            let pageIndex = 1
            let pageSize = 10
            let localeUrl = window.location.host
            let domain = 'https://'+localeUrl
           
            console.log('domain',domain,localeUrl)
            this.$axiosApi.serConfig({pageIndex,pageSize,language,domain}).then(res=>{
            
            this.serveList = res.data.list
            })
        },
        configServiceTranslation(){
            let localeUrl = window.location.host
            let domain = 'https://'+localeUrl
            let target_lang = localStorage.getItem('lang')
            let lang = this.$store.state.lang.dictValue
            this.$axiosApi.configServiceTranslation({domain,target_lang,lang}).then(res=>{

            })
        }

    },
    computed:{
        serveList(){ return this.$store.state.serveList}
    }
}
</script>

<style lang='scss' scoped>
.footer{
   padding:32px 0;
    // background:#f0f0f7;
    margin-top: 32px;
    margin-bottom: 52px;
    .footer_container{
        width:100%;
        text-align: left;
       .footer_group{
        grid-column-gap:16px;
        grid-row-gap:16px;
      
       }
      
    }
    .group_wrapper{
           
           .wrapper_title{
               margin-bottom: 8px;
               padding: 13px 0;
               .title_icon{
                   width:20px;
                   height: 20px;
               }
               .title_text{
                   color:#1f2128;
                   font-weight: 600;
                   line-height: 22px;
                   font-size: 18px;
               }
           }
           .wrapper_item{
               .item_icon{
                   width: 40px;
                   height: 40px;
                   border-radius: 50%;
                   transition: all .15s linear;
                   margin-right:32px;
                   cursor: pointer;
                   overflow: hidden;
                   >img{
                       transition: all .15s linear;
                   }
                   &:hover{
                       >img{
                           transform: scale(1.1);
                       }
                   }
                   &:nth-child(4){
                       margin:0;
                   }
               }
               .wrapper_link_tab{
                   color:#0f0f0f;
                   font-size: 14x;
                  
                   cursor: pointer;
                   &:hover{
                       text-decoration: underline
                   }
               }
               .wrapper_cont{
                  
                   padding: 4px 12px;
                   height: 32px;
                   border: 1px solid #d2d3d4;
                   border-radius: 100px;
                   box-sizing: border-box;
                   transition: all .15s linear;
                   cursor: pointer;
                   &:hover{
                       background:#dcdee5;
                   }
               }
               
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow-x: hidden;
               color:#0f0f0f;
               margin-top:8px;
               .wrapper_tab{
                   font-size:14px;
               }
               
               .tab_icon{
                   
                   width: 16px;
                   height: 16px;
               }
           }
       }
    .empty{
        height: 100px;

    }
    .mobile_footer_group{
        padding:0 16px;
        background:#fff;
        .wrapper_cont{    
            margin-right:10px; 
            margin-bottom:10px; 
            padding: 4px 12px;
            height: 32px;
            border: 1px solid #d2d3d4;
            border-radius: 100px;
            box-sizing: border-box;
            transition: all .15s linear;
            cursor: pointer;
            &:hover{
                background:#dcdee5;
            }
            .tab_icon{
                width: 20px;
                height: 20px;
            }

        }
        .coll_container{
            
            .wrapper_item{
                padding:18px 20px;
                border-bottom:1px solid #e8e9ed;
            }
        }
    }
    /deep/.el-collapse-item__header {
        height: 56px;
        line-height: 56px;
    }
}
@media screen and (max-width:768px){
    .footer{
        margin-left: -16px;
        margin-right:-16px;
       
    }
}

</style>