import Vue from 'vue';
import VueI18n from 'vue-i18n'; // 引入 vue-i18n
// 获取全局状态管理的语言设置
import ElementLocale from 'element-ui/lib/locale'
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; // Element UI 中文语言包
import enLocale from "element-ui/lib/locale/lang/en";   // Element UI 英文语言包
import idLocale from 'element-ui/lib/locale/lang/id'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import deLocale from 'element-ui/lib/locale/lang/de'
import esLocale from 'element-ui/lib/locale/lang/es'
import frLocale from 'element-ui/lib/locale/lang/fr'
import koLocale from 'element-ui/lib/locale/lang/ko'
import viLocale from 'element-ui/lib/locale/lang/vi'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import thLocale from 'element-ui/lib/locale/lang/th'
import arLocale from 'element-ui/lib/locale/lang/ar'
import zh from "./zh";  // 自定义中文语言包
import en from "./en";  // 自定义英文语言包
import id from './id'   //印尼语
import ja from './ja'   //日语
import de from './de'   //德语
import es from './es'   //西班牙语
import fr from './fr'   //法语
import ko from './ko'   //韩语
import vi from './vi'   //越南语
import ru from './ru'   //俄语
import th from './th'   //泰语
import ar from './ar'   //阿拉伯语

// 使用 VueI18n 插件
Vue.use(VueI18n);
ElementLocale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
const messages = {
  zh: {
    ...zh,
    ...zhLocale
  },
  en: {
    ...en,
    ...enLocale
  },
  id:{
    ...id,
    ...idLocale
  },
  ja:{
    ...ja,
    ...jaLocale
  },
  de:{
    ...de,
    ...deLocale
  },
  es:{
    ...es,
    ...esLocale
  },
  ko:{
    ...ko,
    ...koLocale
  },
  fe:{
    ...fr,
    ...frLocale
  },
  vi:{
    ...vi,
    ...viLocale
  },
  ru:{
    ...ru,
    ...ruLocale
  },
  th:{
    ...th,
    ...thLocale
  },
  ar:{
    ...ar,
    ...arLocale
  }
};

const i18n = new VueI18n({
  locale:localStorage.getItem('lang') || "en", // 默认语言从 Vuex 中获取
  messages, // 设置语言包
  silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
  globalInjection: true, // 全局注入
  fallbackLocale: 'en', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文

});

// 配置 Element UI 的 i18n
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value), // 绑定 i18n 的翻译方法
});

export default i18n;
