
import Vue from 'vue'
import Vuex from 'vuex'
import axiosApi from '../utils/api'
import userAvatar from '../assets/image/user_white.png'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      dialogFlg:false,
      dialogContent:{},
      showDialog:false, /* login form */
      lang_flag:false, /* 語言 lialog */
      currency:'',
      lang:'',
      langOptions:[],
      currencyOptions:[],
      userInfo:'',
      token:'',
      logo:'',
      serveList:[],
      localName:'',
      metaInfo:{},
    },
   
    mutations: {
      setDialogFlg(state,value){
        state.dialogFlg = value
      },
      setDialogContent(state,value){
        state.dialogContent = value
      },
      setShowDialog(state,value){
        state.showDialog = value
      },
      setLangOptions(state,value){
        state.langOptions = value
      },
      setCurrencyOptions(state,value){
        state.currencyOptions = value
      },
      setCurrency(state,value){
        state.currency = value
      },
      setLang(state,value){
        state.lang = value
      },
      setToken(state,value){
        state.token = value
      },
      removeToken(state,value){
        state.token = ''
      },
      setLang_flag(state,value){
        state.lang_flag = value
      },
      setUserInfo(state,value){
        state.userInfo=value
      },
      setServeList(state,value){
        state.serveList = value
      },
      setLogo(state,value){
        state.logo = value
      },
      setLocalName(state,value){
        state.localName = value
      },
      setMetaInfo(state,value){
        state.metaInfo = value
      }
    },
    actions:{
      getLanguage({commit,dispatch},{dictType}){
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setLangOptions',res.data)
          let item = res.data.find(v=>v.isDefault=="Y")
          let lan = navigator.language;
          let localLan 
          console.log('浏览器语言',lan)
          if(lan =='zh-CN'){
            localLan = 'zh'
          }
          if(lan =='en-US' || lan=='en'){
            localLan = 'en'
          }
          /* isSetLang 是否设置过语言，没有就默认浏览器设置语言
              
           */
          let isSetLang = JSON.parse(localStorage.getItem('isSetLang')) || false 
          if(!isSetLang){
            res.data.map(v=>{
              if(localLan == v.dictDefaultLabel){
                commit('setLang',v)
                localStorage.setItem('lang',v.dictDefaultLabel)
              }
              if(v.dictDefaultLabel =='en'){
                 commit('setLang',v)
                 localStorage.setItem('lang',v.dictDefaultLabel)
               }
            })
            dispatch('getSerConfig')
            return
          }
          
         

          if(item){
            commit('setLang',item)
            let lang = localStorage.getItem('lang')||''
            if(lang != item.dictDefaultLabel){
              localStorage.setItem('lang',item.dictDefaultLabel)
            }
          }
          else{
            res.data.map(v=>{
              if(v.dictSort ==1){
                commit('setLang',v)
                localStorage.setItem('lang',v.dictDefaultLabel)
               
              }
            })
            
          }
       
          dispatch('getSerConfig')
          
        })
      },
      getCurrency({commit},{dictType}){
      
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setCurrencyOptions',res.data)
          res.data.map(v=>{
            if(v.dictSort ==1){
              commit('setCurrency',v)
            }
            if(v.isDefault == "Y"){
              commit('setCurrency',v)
              return
            }
          })  
        })
      },
      getUserInfo({commit}){
        axiosApi.getUserinfo().then(res=>{
          if(res.code==200){
           let image = res.data.image?res.data.image:userAvatar
           res.data.image = image
            commit('setUserInfo',res.data)
            return true
          }else{
            return false
          }
        })
      },
      getSerConfig({state,commit,dispatch}){
        let language = state.lang.dictValue
        let pageIndex = 1
        let pageSize = 10
        let localeUrl = window.location.host
        let domain = 'https://'+localeUrl
       
        console.log('domain',domain,localeUrl)
        axiosApi.serConfig({pageIndex,pageSize,language,domain}).then(res=>{
          
          if(res.data.list.length==0||!res.data.list){
            dispatch('configServiceTranslation')
            return
          }
          commit('setServeList',res.data.list)
        // this.serveList = res.data.list
        })
      },
      configServiceTranslation({state,commit}){
        let localeUrl = window.location.host
        let domain = 'https://'+localeUrl
        let target_lang = localStorage.getItem('lang')
        let lang = state.lang.dictValue
        axiosApi.configServiceTranslation({domain,target_lang,lang}).then(res=>{
          commit('setServeList',res.data.data)
        })
      },
      getLogo({commit}){
        
      
      }
     

    }
  })
