import request from './request'
class axiosApi{
    // 验证码
    static captchaImage(){
        return request({
            url:'/prod_api/captchaImage',
            method:'get',
        })
    }
     //获取邮箱 验证码 类型 1登录邮箱 2忘记密码邮箱 3修改密码邮箱 4注册邮箱
     static getCode({code,password,account,type}){
        return request({
            url:'/prod_api/userinfo/sendCode',
            method:'post',
            data:{
                code,password,account,type
            }
        })
    }
    // 注册
    static register({name,password,code,confirmPassword,inviteUserCode}){
        return request({
            url:'/prod_api/userinfo/register',
            method:'post',
            data:{
                name,password,code,confirmPassword,inviteUserCode
            }
        })
    }
    // login
    static login({name,password,code}){
        return request({
            url:'/prod_api/userinfo/login',
            method:'post',
            data:{
                name,password,code
            }
        })
    }
   // logout
   static logOut(){
    return request({
        url:'/prod_api/userinfo/logout',
        method:'get',
       
    })
   }
    // changepwd
   static updatePwd({oldPwd,newPwd}){
        return request({
            url:'/prod_api/userinfo/updatePwd',
            method:'post',
            data:{
                oldPwd,newPwd
            }
        })
    }
    // goodslist
    static getGoodsList({pageIndex,pageSize}){
        return request({
            url:'/prod_api/goods/getGoodsList?pageIndex='+pageIndex+'&pageSize='+pageSize,
            method:'get',
            
        })
    }
    // goodDetails
    static getGoodsDetail({goodsId}){
        return request({
            url:'/prod_api/goods/getGoodsDetail?goodsId='+goodsId,
            method:'get',
            
        })
    }
    // preOrderTemplate
    static preOrderTemplate({goodsId}){
        return request({
            url:'/prod_api/order/preOrderTemplate?goodsId='+goodsId,
            method:'get',
            
        })
    }
     // preOrderTemplate  语言模版
     static languageGoodTemplate({categoryId,language}){
        return request({
            url:'/prod_api/order/languageGoodTemplate?categoryId='+categoryId+'&language='+language,
            method:'get',
            
        })
    }
    //收集信息
    static createOrder({goodsId,goodsNum,jsonObject}){
        return request({
            url:'/prod_api/order/createOrder',
            method:'post',
            data:{
                goodsId,goodsNum,jsonObject
            }
        })
    }
    // 上传图片
    static uploadChain({file}){
        return request({
            url:'/prod_api/api/common/uploadChain',
            method:'post',
            data:{
                file
            }
        })
    }
    // 图片链接
    static getPic({pic}){
        return request({
            url:'/prod_api/api/common/getPic?pic='+pic,
            method:'get',
            data:{
                
            }
        })
    }
     // 获取轮播图
     static getBannerList({type,domain}){
        return request({
            url:'/prod_api/api/common/getBannerList?type='+type+'&domain='+domain,
            method:'get',
            data:{
                
            }
        })
    }
    // 订单列表
    static getOrderList({pageIndex,pageSize,status,orderStatus}){
        return request({
            url:'/prod_api/order/getOrderList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&status='+status+'&orderStatus='+orderStatus,
            method:'get',
            data:{
                
            }
        })
    }
    
 
    // 订单详情
    static getOrderDetail({id}){
        return request({
            url:'/prod_api/order/getOrderDetail?id='+id,
            method:'get',
            data:{
                
            }
        })
    }
      // 订单数量
    static getOrderCount(){
        return request({
            url:'/prod_api/order/getOrderCount',
            method:'get',
            data:{
                
            }
        })
    }

     // 提交销售订单交付收集   是否同意修改 -1拒绝 0同意

     static submitDelivery({isAgree,jsonObject,id,refundType,refundReasonId}){
        return request({
            url:'/prod_api/order/submitDelivery',
            method:'post',
            data:{
                isAgree,jsonObject,id,refundType,refundReasonId
            }
        })
    }
    //语言和币种
    /* 
     t_goods_language
     t_currency
     sys_notice_type
    */
    static getDictType({dictType}){
        return request({
            url:'/prod_api/api/common/value?dictType='+dictType,
            method:'get',
            data:{
                
            }
        })
    }
    // 系统公告
    static getSysnotice({pageIndex,pageSize,noticeType}){
        return request({
            url:'/prod_api/sysnotice/getSysNoticeList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&noticeType='+noticeType,
            method:'get',
            data:{
                
            }
        })
    }
    // 公告详情
    static getSysNoticeInfo({noticeId}){
        return request({
            url:'/prod_api/sysnotice/getSysNoticeInfo?noticeId='+noticeId,
            method:'get',
            data:{
                
            }
        })
    }

    static setDictType({lingo,currency}){
        return request({
            url:'/prod_api/api/common/setCurrencyLingo?lingo='+lingo+'&currency='+currency,
            method:'get',
            data:{
                
            }
        })
    }

    // zhifu
    static pay({orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone,token,shopName}){
        return request({
            url:'/prod_api/order/pay',
            method:'post',
            data:{
                orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,
                billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,
                billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone,token,shopName
            }
        })
    }
    // 类别
    static getGoodsDetailList({categoryId}){
        return request({
            url:'/prod_api/goods/getGoodsDetailList?categoryId='+categoryId,
            method:'get',
            data:{
                
            }
        })
    }
    // 用户信息
    static getUserinfo(){
        return request({
            url:'/prod_api/userinfo/detail',
            method:'get',
            data:{
                
            }
        })
    }
     // 搜索及全部游戲
     static getAllGameList({gameAlias,pageIndex,pageSize}){
        return request({
            url:'/prod_api/goods/getAllGameList',
            method:'post',
            data:{
                gameAlias,
                pageIndex,
                pageSize
            }
        })
    }
    // 游戲分类主商品
    static getMainGoodsList({pageIndex,pageSize,gameClass}){
        return request({
            url:'/prod_api/goods/getMainGoodsList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&gameClass='+gameClass,
            method:'get',
            data:{
                
            }
        })
    }
    // 游戲排行榜
    static getRankingList({pageIndex,pageSize}){
        return request({
            url:'/prod_api/goods/getRankingList?pageindex='+pageIndex+'&pageSize='+pageSize,
            method:'get',
            data:{
                
            }
        })
    }
    // 游戲分类列表
    static getGameClassList(){
        return request({
            url:'/prod_api/goods/getGameClassList',
            method:'get',
            data:{
                
            }
        })
    }
     // 博客分类
     static getBlogClass(){
        return request({
            url:'/prod_api/sysnotice/getBlogClass',
            method:'get',
            data:{
                
            }
        })
    }
      // 博客列表
      static getBlogList({pageIndex,pageSize,classId,language}){
        return request({
            url:'/prod_api/sysnotice/getBlogList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&classId='+classId+'&language='+language,
            method:'get',
            data:{
                
            }
        })
    }
     // 博客详情
     static getBlogInfo({noId,language}){
        return request({
            url:'/prod_api/sysnotice/getBlogInfo?noId='+noId+'&language='+language,
            method:'get',
            data:{
                
            }
        })
    }
    //博客翻译 
    static blogTranslation({id,target_lang,lang}){
        return request({
            url:'/prod_api/sysnotice/blogTranslation',
            method:'post',
            data: {
                id,
                target_lang,
                lang
            },
        })
    }
     // 博客详情 1:google 2:fb 3:line 4:推特 5:VK 6:tiktok
    static threeOauth({type}){
        return request({
            url:'/prod_api/userinfo/threeOauth?type='+type,
            method:'get',
            data:{
                
            }
        })
    }
    // 三方登录
    static threeLoginMes({id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName}){
        return request({
            url:'/prod_api/userinfo/threeLoginMes',
            method:'post',
            data:{
                id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName
            }
        })
    }
    // 绑定三方登录
    static bindThreeAccount({id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName}){
        return request({
            url:'/prod_api/userinfo/bindThreeAccount',
            method:'post',
            data:{
                id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName
            }
        })
    }
      // 用户信息修改
      static updUserInfo({avatar,nickName,oldPassword,newPassword,code}){
        return request({
            url:'/prod_api/userinfo/updUserInfo',
            method:'post',
            data:{
                avatar,nickName,oldPassword,newPassword,code
            }
        })
    }
    // 查询第三方绑定
    static threeAccountList(){
        return request({
            url:'/prod_api/userinfo/queryThreeAccountList',
            method:'get',
            data:{
                
            }
        })
    }
      // 查询第三方绑定
    static bindEmail({email,password,confirmPassword,code}){
        return request({
            url:'/prod_api/userinfo/bindEmail',
            method:'post',
            data:{
                email,password,confirmPassword,code
            }
        })
    }

      // 综合服务列表查询
      static serConfig({pageIndex,pageSize,language,domain}){
        return request({
            url:'/prod_api/serConfig/list?pageIndex='+pageIndex+'&pageSize='+pageSize+'&language='+language+'&domain='+domain,
            method:'get',
            data:{
                
            }
        })
    }
     // 综合服务列表查询
     static getConfigService({id,language}){
        return request({
            url:'/prod_api/serConfig/getConfigService?id='+id+'&language='+language,
            method:'get',
            data:{
                
            }
        })
    }
     // 查询网站导航列表
    static navigationList({domain}){
        return request({
            url:'/prod_api/serConfig/navigationList?domain='+domain,
            method:'get',
            data:{
                  
            }
        })
    }
     // 服务翻译
     static configServiceTranslation({domain,target_lang,lang}){
        return request({
            url:'/prod_api/serConfig/configServiceTranslation',
            method:'post',
            data:{
                domain,target_lang,lang
            }
        })
    }
     // 服务详情翻译
     static serviceDetailTranslation({id,target_lang,lang}){
        return request({
            url:'/prod_api/serConfig/serviceDetailTranslation',
            method:'post',
            data:{
                id,target_lang,lang
            }
        })
    }
  
}
export default  axiosApi