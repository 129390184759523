import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index'
Vue.prototype.$axios = axios

import store from './store/store'
import './style/global.scss'
import './style/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
// Vue.use(ElementUI)
import i18n from './i18n/langs/lang'
// import 'vue-pickers/example/css/app.279fbbfe.css';
Vue.use(ElementUI,
  { i18n: (key, value) => i18n.t(key, value) }// 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
)

import 'animate.css';
import axiosApi from './utils/api'
Vue.prototype.$axiosApi = axiosApi 
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)
import Meta from 'vue-meta'; 
Vue.use(Meta);

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import cookies from './utils/cookie'

router.beforeEach((to,form,next)=>{
  const token = cookies.getToken()
  let lang = localStorage.getItem('lang')
  // store.commit('setMetaInfo',{})
  
  NProgress.start()
  // console.log('beforeEach',to,form)
  if(token){
    next()
  }else if(!token){ 
    if(to.meta.rule){
      NProgress.done()
      store.commit('setShowDialog',true)
      return
    }    
    else{

      next()
    }
    
    // next({ name:'login' })
    console.log('!token')
  }

})

router.afterEach((to,form,next)=>{
  NProgress.done()
})
import VueSocialLogin from 'vue-social-login';
Vue.use(VueSocialLogin, {
  providers: {
    // twitter: {
    //   clientId: 'Ke1onjeIIyICIs5CB4HdPUw6c', // 替换为你在推特开发者平台创建应用时获得的API Key
    //   redirectUri: 'http://localhost:8080', // 替换为你的回调网址，需在推特开发者平台设置的应用回调网址中添加该地址
    //   scope: [] // 可根据需要设置请求的权限范围，如['tweet.read', 'users.read']等
    // },
    // line: {
    //   clientId: '2006595014',// 替换为获取到的Channel ID
    //   redirectUri: 'http://localhost:8080',// 替换为LINE应用中设置的回调URL，且需要在LINE开发者控制台中添加该回调URL
    //   scope: ['profile','openid']// 根据需要设置请求的权限范围
    // },
    // vk: {
    //   clientId: '52751439',// 用获取到的App ID替换
    //   redirectUri: 'http://localhost:8080',// 替换为在VK开发者控制台中设置的回调URL
    //   scope: ['email']// 根据需要设置权限范围，如获取用户邮箱等
    // }
  }
});


import { StripeElements } from 'vue-stripe-elements';
 
Vue.component('StripeElements', StripeElements)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  metaInfo(){
    return {
      title: 'Game Top-Up Platform - Fast and Secure Services',
      meta: [
          {
            name: "description",
            content: "Fast and secure game top-up services for popular games like 'Genshin Impact', 'PUBG', and 'Honor of Kings"            }
      ]
    }
  },
  render: h => h(App),
}).$mount('#app')